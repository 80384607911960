import { Card, CardContent, CardHeader, Dialog, FormControl, IconButton, InputLabel,  MenuItem, Select, Table, TableCell, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { upperCase } from 'lodash';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import CloseIcon from "@mui/icons-material/Close";



const getStates = async (setStates) => {
    var endpoint = '/admin/announcements/states.json';
    try {
      const res = await fetch( process.env.REACT_APP_API_HOST + endpoint, {
        method: "GET",
      });
      res.json().then((res)=> {
        setStates(res)
          console.log("response" +JSON.stringify(res))
        });
        
      
      if (!res.ok) {
        throw new Error(`Error ${res.status}: ${res.statusText}`);
      }
    } catch (err) {
    }
  };


  const getAgencies = async (setAgencies) => {
    var endpoint = '/admin/announcements/agencies.json';
    try {
      const res = await fetch( process.env.REACT_APP_API_HOST + endpoint, {
        method: "GET",
      });
      res.json().then((res)=> {
        setAgencies(res)
          console.log("response" +JSON.stringify(res))
        });
        
      
      if (!res.ok) {
        throw new Error(`Error ${res.status}: ${res.statusText}`);
      }
    } catch (err) {
    }
  };

  const getRoadways = async (setRoadways) => {
    var endpoint = '/admin/announcements/roadways.json';
    try {
      const res = await fetch( process.env.REACT_APP_API_HOST + endpoint, {
        method: "GET",
      });
      res.json().then((res)=> {
        setRoadways(res)
          console.log("response" +JSON.stringify(res))
        });
        
      
      if (!res.ok) {
        throw new Error(`Error ${res.status}: ${res.statusText}`);
      }
    } catch (err) {
    }
  };

  const getPriorities = async (setPriorities) => {
    var endpoint = '/admin/announcements/priorities.json';
    try {
      const res = await fetch( process.env.REACT_APP_API_HOST + endpoint, {
        method: "GET",
      });
      res.json().then((res)=> {
        setPriorities(res)
          console.log("response" +JSON.stringify(res))
        });
        
      
      if (!res.ok) {
        throw new Error(`Error ${res.status}: ${res.statusText}`);
      }
    } catch (err) {
    }
  };


  const getAnnouncements = async (state,agency,priority,roadway,setAnnouncements) => {
    var endpoint = '/admin/announcements/all.json?state=Illinois';
    if(state!=="all")
        endpoint= endpoint + "&state=" + state
    if(agency!=="all")
        endpoint= endpoint + "&agency=" + agency
    if(priority!=="all")
        endpoint= endpoint + "&priority=" + upperCase(priority)
    if(roadway!=="all")
        endpoint = endpoint + "&roadway=" + roadway
    try {
      const res = await fetch( process.env.REACT_APP_API_HOST + endpoint, {
        method: "GET",
      });
      res.json().then((res)=> {
        console.log("response" +JSON.stringify(res))
        setAnnouncements(res);
        });
        
      
      if (!res.ok) {
        throw new Error(`Error ${res.status}: ${res.statusText}`);
      }
      
   
    } catch (err) {
    }
  };

  const getAnnouncementLogs = async (announcementId,setAnnouncementLogs) => {
    var endpoint = '/admin/announcements/'+announcementId+'/logs.json';
    try {
      const res = await fetch( process.env.REACT_APP_API_HOST + endpoint, {
        method: "GET",
      });
      res.json().then((res)=> {
        setAnnouncementLogs(res)
          console.log("response "+endpoint+" :" +JSON.stringify(res))
        });
        
      
      if (!res.ok) {
        throw new Error(`Error ${res.status}: ${res.statusText}`);
      }
    } catch (err) {
    }
  };

    const AnnouncementsList = ( props) => {
        const [announcements, setAnnouncements] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        const [allData,setAllData] = React.useState(null);
        const [states,setStates] = React.useState(null);
        const [agencies,setAgencies] = React.useState(null);
        const [roadways,setRoadways] = React.useState(null);
        const [priorities,setPriorities] = React.useState(null);

        const [announcementLogs, setAnnouncementLogs] = React.useState(null);

        const [selectedState , setSelectedState] = React.useState("all");
        const [selectedAgency , setSelectedAgency] = React.useState("all");
        const [selectedPriority , setSelectedPriority] = React.useState("all");
        const [selectedRoadway , setSelectedRoadway] = React.useState("all");

        const [openDispAnnouncementLog, setOpenDispAnnouncementLog] = React.useState(false);

        useEffect(()=>{
                getStates(setStates);
                getAgencies(setAgencies);
                getPriorities(setPriorities);
                getRoadways(setRoadways);
        },[])
      

        useEffect(()=>{
            console.log("States"+states)
            console.log("Agencies"+agencies)
            console.log("Priorites"+priorities)
            console.log("roadways"+roadways)

            FilterAnnouncements();
            Announcements();
            if(selectedState!=null && selectedAgency!=null && selectedPriority!=null && selectedRoadway!=null)
                     getAnnouncements(selectedState,selectedAgency,selectedPriority,selectedRoadway,setAnnouncements);

        },[states,roadways,priorities,agencies,selectedState,selectedAgency,selectedPriority,selectedRoadway])


        useEffect(()=>{

            DispAnnouncementLogs();
            console.log("announcementLogs--->"+JSON.stringify(announcementLogs))

        },[openDispAnnouncementLog,announcementLogs])

        const handleStateChange =(e)=>{
            setSelectedState(e.target.value);
            console.log(e.target.value);
            
         }
         const handleAgencyChange =(e)=>{
            setSelectedAgency(e.target.value);
            console.log(e.target.value);
            
         }
         const handlePriorityChange =(e)=>{
            setSelectedPriority(e.target.value);
            console.log(e.target.value);
            
         }
         const handleRoadwayChange =(e)=>{
            setSelectedRoadway(e.target.value);
            console.log(e.target.value);  
         }

         const handleAnnouncementLogs =(announcementId,setOpenDispAnnouncementLog)=>{
                // alert(announcementId);
                console.log("announcementId"+announcementId);
                setOpenDispAnnouncementLog(true);
                getAnnouncementLogs(announcementId,setAnnouncementLogs);
                

         }

         const handleClose = (event) => {
            setOpenDispAnnouncementLog(false);
            setAnnouncementLogs([])
          };

         const DispAnnouncementLogs =()=>{
            if(announcementLogs!=null && openDispAnnouncementLog)
            return (
        <div>
            <Dialog fullWidth maxWidth onClose={handleClose}  open={openDispAnnouncementLog} >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                // color: (theme) => theme.palette.grey[1500],
              }}
              >
              <CloseIcon />
            </IconButton>
            {/* <Card sx={{ overflow: "auto" }}> */}
            <Card sx={{ borderRadius: 3 }}>
              <CardHeader
                title={<font className="layer-title"> AnnouncementLogs </font>}
                sx={{ pb: 0, paddingLeft: 1, paddingRight: 1 }}
                align='center'
                className={"styledtablerow"}
              />

              <CardContent
                sx={{ paddingTop: 1, paddingLeft: 0, paddingRight: 0 }}
              >
            <Table size='small'>
                <TableRow className="report-header">
                    <TableCell>LogId</TableCell>
                    <TableCell>AnnouncementId</TableCell>
                    <TableCell>Operator</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>UpdatedTime</TableCell>
                    <TableCell>Mode</TableCell>
                </TableRow>

            {(announcementLogs.map((eachannounceLog, idx)=>{
                
                    return <TableRow className={idx % 2 === 0 ? "styledtablerow" : ""}>
                        <TableCell>
                            {eachannounceLog.id}
                        </TableCell>
                        <TableCell>
                            {eachannounceLog.announcementId}
                        </TableCell>
                        <TableCell>
                            {eachannounceLog.operator}
                        </TableCell>
                        <TableCell>
                            {eachannounceLog.announcementTitle}
                        </TableCell>
                        <TableCell>
                            {eachannounceLog.timestamp}
                        </TableCell>
                        <TableCell>
                            {eachannounceLog.updateType}
                        </TableCell>
                    </TableRow>
                    
            }))}
                </Table>
                </CardContent>
                </Card>
                </Dialog>
                </div>
                )

              else
                return null
         }

        const FilterAnnouncements =()=>{
            if(states!=null && agencies!=null && priorities!=null && roadways!=null)
            return (
                <Box>
                    <Typography variant='h5'sx={{ mb: 1, textAlign: "center", mt: 2 }}> Manage Announcements </Typography>
                    <Grid >
                        <Grid item xs={12}>     
                            <FormControl sx={{width: '30ch', marginLeft:2, marginBottom:5}}>
                                <InputLabel id="FilterAnnouncementsState-label"> State </InputLabel>
                                    <Select 
                                        labelId="FilterAnnouncementsState-label"
                                        id="state-selection"
                                        value={selectedState}
                                        label="State"
                                        onChange={handleStateChange}
                                        name="State"
                                    >
                                        <MenuItem value="all" key="all"> -all- </MenuItem>
                                    {states.map(eachState =>
                                        <MenuItem value={eachState} key={eachState}>{eachState} </MenuItem>
                                    )}
                                    </Select>
                            </FormControl>
                            <FormControl sx={{width: '30ch', marginLeft:2, marginBottom:5}}>
                                <InputLabel id="FilterAnnouncementsAgency-label"> Agency </InputLabel>
                                    <Select 
                                        labelId="FilterAnnouncementsAgency-label"
                                        id="agency-selection"
                                        value={selectedAgency}
                                        label="Agency"
                                        onChange={handleAgencyChange}
                                        name="Agency"
                                    >
                                        <MenuItem value="all" key="all"> -all- </MenuItem>
                                    {agencies.map(eachagency =>
                                        <MenuItem value={eachagency} key={eachagency}> {eachagency} </MenuItem>
                                    )}
                                    </Select>
                            </FormControl>
                            <FormControl sx={{width: '30ch', marginLeft:2, marginBottom:5}}>
                                <InputLabel id="FilterAnnouncementsPriority-label"> Priority </InputLabel>
                                    <Select 
                                        labelId="FilterAnnouncementsPriority-label"
                                        id="Priority-selection"
                                        value={selectedPriority}
                                        label="Priority"
                                        onChange={handlePriorityChange}
                                        name="Priority"
                                    >
                                    <MenuItem value="all" key="all"> -all- </MenuItem>
                                        {priorities.map(eachpriority =>
                                            <MenuItem value={eachpriority} key={eachpriority}> {eachpriority} </MenuItem>
                                        )}
                                    </Select>
                            </FormControl>
                            <FormControl sx={{width: '30ch', marginLeft:2, marginBottom:5}}>
                                <InputLabel id="FilterAnnouncementsRoadway-label"> Roadway </InputLabel>
                                    <Select 
                                        labelId="FilterAnnouncementsRoadway-label"
                                        id="Roadway-selection"
                                        value={selectedRoadway}
                                        label="Roadway"
                                        onChange={handleRoadwayChange}
                                        name="Roadway"
                                    >
                                    <MenuItem value="all" key="all"> -all- </MenuItem>
                                        {roadways.map(eachroadway =>
                                    <MenuItem value={eachroadway} key={eachroadway}> {eachroadway} </MenuItem>
                                        )}
                                    </Select>
                            </FormControl>
                            <Typography>
                                Click on an announcement title to edit that announcement's settings.
                            </Typography>
                        </Grid>
                    </Grid>    
                </Box>
            )
        }
        
        const Announcements =()=>{
            if(announcements!= null && announcements.length > 0)
            return (<Card>
                <Table size='small'>
                    <TableRow className="report-header">
                        <TableCell> ID </TableCell>
                        <TableCell> Title </TableCell>
                        <TableCell> Priority </TableCell>
                        <TableCell> Agency </TableCell>
                        {/* <TableCell> MoreInfoLink </TableCell> */}
                        <TableCell> Expiration Date </TableCell>
                        <TableCell> AnnouncementLogs </TableCell>
                    </TableRow>
        {(announcements.map((eachAnnouncement, idx)=>{
            return <>            
                <TableRow className={idx % 2 === 0 ? "styledtablerow" : ""}>
                    <TableCell>
                        {eachAnnouncement.id}
                    </TableCell>
                    <TableCell  align='left'>
                        {eachAnnouncement.title}
                    </TableCell>
                    <TableCell>
                        {eachAnnouncement.priority}
                    </TableCell>
                    <TableCell>
                        {eachAnnouncement.agency}
                    </TableCell>
                    {/* <TableCell>
                        <a href="{eachAnnouncement.link}" target="_blank" rel="noopener noreferrer" className="hyper-links" > {eachAnnouncement.link} </a>
                    </TableCell> */}
                    <TableCell>
                        {eachAnnouncement.expirationDate}
                    </TableCell>
                    <TableCell>
                        
                    <Link style={{ textDecoration: "none" }} className="hyper-links" onClick={(e) => handleAnnouncementLogs(eachAnnouncement.id,setOpenDispAnnouncementLog)}>
                            AnnouncementLogs
                    </Link>
                    <LinkIcon fontSize="small" className="link-icon" />
                    </TableCell>
                    
                </TableRow>
            </>
        }))}
        </Table> 
        <DispAnnouncementLogs/>
        </Card>
            )
            else{
                return (
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                        <Typography variant="h6">No Data Found</Typography>
                     </Box>
                )
            }
        }

        return (
            <>
            <FilterAnnouncements/>
            <Announcements/>
            </>
        )
    }

    const module = {
        routeProps: {
            path: 'Announcements',
            component: AnnouncementsList 
        },

        name: "Announcements",
        label: "Announcements",
        title: "Announcements",
        showOnMenu: true,
    }
    export default module;