import L from "leaflet";
import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import LinkIcon from "@mui/icons-material/Link";

var specialEventIcon = L.icon({
  iconUrl:
    process.env.PUBLIC_URL + "/mapicons/SpecialEvents/Special_Event_v14.png",

  iconSize: [21, 21], // size of the icon
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const getLeafletIcon = (feature, latlng, map, pane) => {
  var title = " Special Event - " + getDesc(feature.properties);
  return L.marker(latlng, {
    icon: specialEventIcon,
    title: title,
    alt: title,
    pane: pane,
  });
};

const getDesc = (properties) => {
  return properties.dec;
};

const PopupTemplate = (props) => {
  const feature = props.feature;
  return <SpecialEvent feature={feature} />;
};

const SpecialEvent = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar
            alt="Special Event"
            src={
              process.env.PUBLIC_URL +
              "/mapicons/SpecialEvents/Special_Event_v14.png"
            }
            sx={{ width: 21, height: 21 }}
            aria-label="specialevent"
          ></Avatar>
        }
        title={<font className="layer-title"> Special Event </font>}
        className="styledtablerow"
        sx={{ pb: 0, paddingTop: 1, paddingBottom: 0, paddingLeft: 1 }}
      />
      <CardContent
        size="small"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 1,
          paddingRight: 1,
          marginBottom: -3,
        }}
      >
        <Table size="small">
          <TableRow>
            <TableCell className={"layertablecellLeft"}>
              <div>{properties.locDesc}</div>
              {/* {properties.desc} */}
              <span dangerouslySetInnerHTML={{ __html: properties.desc }} />
            </TableCell>
          </TableRow>
        </Table>
        <Table size="small" style={{ cellSpacing: 0 }}>
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>
              Closure&nbsp;Details
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.closure}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>Time Period</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.time}
            </TableCell>
          </TableRow>
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>Duration</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.dur}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>Severity</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.sev}
            </TableCell>
          </TableRow>
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>Source</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.src}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>Last Updated</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.lstUpd}
            </TableCell>
          </TableRow>
          {properties.mo && (
            <TableRow className="styledtablerow">
              <TableCell className={"layertablecellLeft"}>
                Moving Operation
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {String(properties.mo)}
              </TableCell>
            </TableRow>
          )}
        </Table>
      </CardContent>
    </Card>
  );
};

/*const PopupTemplate = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  return (
      <Card sx={{mt:1}}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: blue[500] }} aria-label="specialevent">
            Special Event
          </Avatar>
        }
        title={properties.locDesc}
        subheader={`Last Updated: ${properties.lstUpd}`}
      />
      </Card>
  )
}*/

const module = {
  name: "Special Event",
  endpoint: "/specialEventMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  //getTooltip: getTooltip,
  updateDelay: 180,
  mapLayerIcon:
    process.env.PUBLIC_URL + "/mapicons/SpecialEvents/Special_Event_v14.png",
  maxZoom: 9,
  zIndex: 625,
};

export default module;
