import L from "leaflet";
import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

var weatherstationIcon = L.icon({
  iconUrl: process.env.PUBLIC_URL + "/mapicons/WeatherStations/Weather_v14.png",
  iconSize: [21, 21], // size of the icon
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const getLeafletIcon = (feature, latlng, map, pane) => {
  var title = "  Weather Station - " + getStatus(feature.properties);
  return L.marker(latlng, {
    icon: weatherstationIcon,
    title: title,
    alt: title,
    pane: pane,
  });
};

const getStatus = (properties) => {
  return properties.stat;
};

const PopupTemplate = (props) => {
  const feature = props.feature;
  return <WeatherStation feature={feature} />;
};

const WeatherStation = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  const atmosphere = properties.atmos.replace("&#176;", "°");

  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar
            alt="Weather Station"
            src={
              process.env.PUBLIC_URL +
              "/mapicons/WeatherStations/Weather_v14.png"
            }
            sx={{ width: 21, height: 21 }}
            aria-label="Weatherstation"
          ></Avatar>
        }
        title={<font className="layer-title"> Weather Station </font>}
        sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
        className={"styledtablerow"}
      />

      <CardContent
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 1,
          paddingRight: 1,
          marginBottom: -3,
        }}
      >
        <Table size="small">
          <TableRow>
            <TableCell className={"layertablecellLeft"} colSpan={2}>
              <div>{properties.locDesc}</div>
              {`${properties.tm}`}
            </TableCell>
          </TableRow>
          <TableRow className={"styledtablerow"}>
            <TableCell className={"layertablecellLeft"}>Status</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.stat}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>
              Atmospheric Readings
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {atmosphere}
            </TableCell>
          </TableRow>
          <TableRow className={"styledtablerow"}>
            <TableCell className={"layertablecellLeft"}>
              Precipitation
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.precip}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>Pavement</TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.pvmnt}
            </TableCell>
          </TableRow>
        </Table>
      </CardContent>
    </Card>
  );
};

const module = {
  name: "Sensors",
  endpoint: "/wssMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  // getTooltip: getTooltip,
  updateDelay: 180,
  mapLayerIcon:
    process.env.PUBLIC_URL + "/mapicons/WeatherStations/Weather_v14.png",
  maxZoom: 10,
  zIndex: 615,
};

export default module;
