import * as React from 'react';
import Typography from '@mui/material/Typography';
import Alerts from './Alerts'
import LayersContext from '../context/LayersContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { NavLink } from "react-router-dom";
import mapsJson from '../maps.json'
import useMediaQuery from '@mui/material/useMediaQuery';
import Pantocitysearch from './PanToCitySearch';
import { useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummaryReverse } from './Accordion';


export default function MapLegend(props) {
    const [showCongestion, setShowCongestion] = React.useState(false);
    const modules = props.modules;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        if (modules && modules.length > 0) {
            const congestionModule = modules.some(module => module.congestionLayer)
            if (congestionModule) {
                setShowCongestion(true);
            } else {
                setShowCongestion(false)
            }
        }
    }, [modules])

    return (
        <Box className="maplayers-container" sx={{
            backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, 1)'
                : 'rgba(255, 255, 255, 1)',
            mt: 1
        }}>
            <Accordion>
                <Alerts />
            </Accordion>
            <Accordion defaultExpanded={matches} id="map-controls-body">
                <AccordionSummaryReverse
                    aria-controls="layer-controls-content"
                    id="layer-controls-header"
                    className="controls-header"
                >
                    <Typography variant="subtitle3" sx={{ fontWeight: "bold" }}>Layer Controls</Typography>
                </AccordionSummaryReverse>
                <AccordionDetails>
                    <LayerControls {...props}></LayerControls>
                    {showCongestion &&
                        <>
                            {/* <LayerControl {...props} title="Highway Congestion" url='/mapicons/Traffic_Congestion_v16.png' key="Highway" /> */}
                            <LayerControl {...props} title="Arterial Congestion" url={process.env.PUBLIC_URL + '/mapicons/Arterial_Congestion_v18.png'} key="Arterial" />

                        </>
                    }
                </AccordionDetails>
            </Accordion>
            {/* <Accordion>
                <AccordionSummaryReverse
                    aria-controls='maps-content'
                    id='maps-header'
                    className="controls-header"
                >
                    <Typography variant="subtitle3" sx={{ fontWeight: "bold" }}>Maps</Typography>
                </AccordionSummaryReverse>
                <AccordionDetails>
                    <Maps {...props}></Maps>
                </AccordionDetails>
            </Accordion> */}
            <Pantocitysearch {...props} />
        </Box>
    )
}

const LayerControls = (props) => {
    const modules = props.modules;
    return (
        <>
            {modules.map(module => {
                if (module.showOnMap && module.mapLayerIcon && module.name !== "Real Time Traffic") {
                    if (module.name === "Congestion") {
                        return (
                            <LayerControl {...props} title="Highway Congestion" url={process.env.PUBLIC_URL + '/mapicons/Traffic_Congestion_v16.png'} key="Highway" legendTemplate={module.LegendTemplate} />
                        )
                    }
                    else {
                        return (
                            <LayerControl {...props} title={module.name} url={module.mapLayerIcon} key={module.name} legendTemplate={module.LegendTemplate} />
                        )
                    }
                }
                else {
                    return null;
                }
            })
            }
        </>
    )
}


const LayerControl = (props) => {
    const layersContext = React.useContext(LayersContext);
    const title = props.title;
    const [on, setButtonOn] = React.useState(undefined !== layersContext[title] ? layersContext[title].on : true);
    const [visible, setVisible] = React.useState(undefined !== layersContext[title] ? layersContext[title].visible : true)
    const url = props.url;
    const setLayersOn = props.setLayersOn;
    const LegendTemplate = props.legendTemplate;

    const handleChange = (e, on) => {
        setButtonOn(!on);
    }

    React.useEffect(() => {
        if (undefined !== on && title) {
            setLayersOn(layersContext => {
                let newLayersContext = { ...layersContext };
                newLayersContext[title] = { ...newLayersContext[title], on: on };
                return newLayersContext;
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [on, title])

    React.useEffect(() => {
        if (undefined !== layersContext[title]) {
            setButtonOn(layersContext[title].on);
            setVisible(layersContext[title].visible)
        }
    }, [layersContext, title])

    return (
        <>
            <Box sx={{ ml: 1 }} title={visible ? "" : `Not displayed, zoom in to see ${title}`}>
                <FormControlLabel control={<Checkbox checked={on} disabled={!visible}
                    onChange={e => { handleChange(e, on) }}
                    inputProps={{ 'aria-label': 'controlled' }} />}
                    label={<Box sx={{ display: "flex", alignItems: "center" }} >
                        <img src={url} key={title} className="legend-img" alt='' width="22px" height="22px" style={{ marginRight: "1px" }} />
                        <Typography sx={{ ml: 1 }} component="span"
                            fontSize={13}>{title}</Typography>
                    </Box>
                    } />

            </Box>
            {on && LegendTemplate ? <LegendTemplate {...props} /> : null}

        </>
    )
}

const Maps = (props) => {
    return (
        <>
            <Box sx={{ fontWeight: "bold" }}><label style={{ marginLeft: 8 }}> Illinois </label></Box>
            {mapsJson.map((link) => {
                if (link["state"] === 'Illinois')
                    return <Box key={link.id}>
                        <NavLink style={{ marginLeft: 5 }} className="map-link" to={`/map/${link["id"]}`} > {link["title"]} </NavLink>
                    </Box>
                else return null;
            })}
            <Box sx={{ fontWeight: "bold" }}><label style={{ marginLeft: 8 }}> Indiana </label></Box>
            {mapsJson.map((link) => {
                if (link["state"] === 'Indiana')
                    return <Box key={link.id}>
                        <NavLink style={{ marginLeft: 5 }} className="map-link" to={`/map/${link["id"]}`} > {link["title"]} </NavLink>
                    </Box>
                else return null;
            })}
            <Box sx={{ fontWeight: "bold" }}><label style={{ marginLeft: 8 }}> Wisconsin </label></Box>
            {mapsJson.map((link) => {
                if (link["state"] === 'Wisconsin')
                    return <Box key={link.id}>
                        <NavLink style={{ marginLeft: 5 }} className="map-link" to={`/map/${link["id"]}`}> {link["title"]} </NavLink>
                    </Box>
                else return null;
            })}
        </>
    )
}


