import DMSArchive from './DMSArchive';
import AdminLayout from './AdminLayout';
import Announcements from './Announcements';


const module = {
    routeProps : {
        path: 'Admin',
        component: AdminLayout
    },

    name: "Admin",
    label: "Admin",
    submodules: [DMSArchive, Announcements],
    showOnHeader: true,
    
}

export default module;